<template>
    <div class="py-4 container-fluid">
        <div class=" row">
            <div class="col-12">
                <div class="card">
                    <div class="card-header pb-0 d-flex justify-content-between align-items-center">
                        <h6 class="mb-0 fw-bolder">Fee Collection Department</h6>
                        <div>
                            <small class="text-bold ">Sort By:</small>
                            <small @click="sortListByBatch" class="mx-1">Batch</small>
                            <small @click="sortListName" class="mx-1">Name</small>

                            <select name="" id=""
                                class="text-secondary px-3 py-2 rounded border border-2 font-weight-bold text-xs">
                                <option value="10">10</option>
                                <option value="50">50</option>
                                <option value="all">All</option>
                            </select>
                        </div>
                    </div>
                    <div class="card-body px-0 pt-0 pb-2">
                        <div class="table-responsive p-0">
                            <table class="table align-items-center mb-0">
                                <thead>
                                    <tr>
                                        <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Name
                                        </th>
                                        <th
                                            class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">
                                            Group</th>
                                        <th
                                            class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">
                                            Courses</th>
                                        <th
                                            class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">
                                            Fee</th>
                                        <th
                                            class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                                            Action</th>
                                        <th
                                            class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                                            Status</th>
                                        <!-- <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Employed</th> -->
                                        <!-- <th class="text-secondary opacity-7"></th> -->
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="student in students" :key="student.id">
                                        <td>
                                            <div class="d-flex px-2 py-1">
                                                <div>
                                                    <!-- <img :src="'https://ui-avatars.com/api/?name=' + student.firstname + ' ' + student.lastname"
                                                        class="avatar avatar-sm me-3" alt="user1" /> -->
                                                    <img :src="student.profile ? 'https://softworica.com/storage/' + student.profile : 'https://ui-avatars.com/api/?name=' + student.firstname + ' ' + student.lastname"
                                                        class="avatar avatar-md me-3" style="height: auto !important;"
                                                        alt="user1" />
                                                </div>
                                                <div class="d-flex flex-column justify-content-center">
                                                    <h6 class="mb-0 text-sm" info-id="{{ data }}">{{ student.firstname }}
                                                        <span v-if="student.middlename != null">{{ student.middlename
                                                        }}</span>
                                                        {{ student.lastname }}
                                                    </h6>
                                                    <p class="text-xs text-secondary mb-0">{{ student.email }}</p>
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <p class="text-xs font-weight-bold mb-0">{{ student.course_id ?
                                                student.course.name : 'NA' }}</p>
                                            <p class="text-xs text-secondary mb-0">{{ student.group_id ? student.group.name
                                                :
                                                'NA' }}</p>
                                        </td>
                                        <td>
                                            <p v-for="course in student.courses"
                                                style="font-size: 12px !important; margin-bottom: 0px;" :key="course.id">{{
                                                    course.name }}</p>
                                        </td>
                                        <td>
                                            <!-- <p></p> -->
                                            <p
                                                style="font-size: 12px !important; margin-bottom: 0px; font-weight: 400 !important;">
                                                Total: Rs {{ calculateTotalExpected(student) }}</p>
                                            <p
                                                style="font-size: 12px !important; margin-bottom: 0px; font-weight: 400 !important;">
                                                Collected: Rs {{ student.feestatus ? student.feestatus.amount : '0' }}</p>
                                            <p
                                                style="font-size: 12px !important; margin-bottom: 0px; font-weight: 400 !important;">
                                                Remaining: Rs {{ student.feestatus ? calculateTotalExpected(student) -
                                                    student.feestatus.amount : calculateTotalExpected(student) }}</p>
                                        </td>
                                        <td class="align-middle text-center text-sm">
                                            <router-link :to="{ name: 'ViewStudent', params: { id: student.id } }"
                                                class="text-secondary font-weight-bold text-xs">View</router-link>
                                            <!-- <span class="badge badge-sm bg-gradient-success">Online</span> -->
                                        </td>
                                        <td class="align-middle text-center">
                                            <collect-fee :student="student"></collect-fee>
                                            <!-- <span v-if="student.feestatus"></span>
                                            <span v-else><small style="font-size: 11px;" class="fw-normal">Started Collecting</small></span> -->
                                            <!-- {{   }} -->
                                        </td>
                                        <!-- <td class="align-middle text-center">
                    <span class="text-secondary text-xs font-weight-bold">23/04/18</span>
                </td>
                <td class="align-middle">
                    <a href="javascript:;" class="text-secondary font-weight-bold text-xs" data-toggle="tooltip"
                        data-original-title="Edit user">Edit</a>
                </td> -->
                                    </tr>

                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="mt-4 row">
            <div class="col-12">
            </div>
        </div>
    </div>
</template>
  
<script>
import axiosConfig from '../../baseaxios';
import CollectFee from '../components/students/collect-fee.vue';
export default {
    components: {
        CollectFee
    },
    data() {
        return {
            students: [],
            limit: 50
        }
    },
    mounted() {
        var limit = 'all';
        var filter = 'all';
        axiosConfig.get(`back/students/list/${limit}/${filter}`).then(resp => {
            this.students = resp.data.students;
            console.log(resp)
        })
        console.log("Informations")
    },
    methods: {
        calculateTotalExpected(student) {
            var tota = 0;
            for (let i = 0; i < student.courses.length; i++) {
                const cour = student.courses[i];

                tota = tota + parseInt(cour.fee_sp);
            }
            return tota;
        },
        sortListByBatch() {
            // Use the Array.prototype.sort() method to sort the list based on the "propertyName" property
            if (this.sorted) {
                this.students.sort((a, b) => {
                    // Change 'propertyName' to the actual property you want to sort by
                    return a.batch.name.localeCompare(b.batch.name);
                });

            } else {
                this.students.sort((a, b) => {
                    // Change 'propertyName' to the actual property you want to sort by
                    return b.batch.name.localeCompare(a.batch.name);
                });
            }
            this.sorted = !this.sorted;
        },
        sortListName() {
            // Use the Array.prototype.sort() method to sort the list based on the "propertyName" property
            if (this.sorted) {
                this.students.sort((a, b) => {
                    // Change 'propertyName' to the actual property you want to sort by
                    return a.firstname.localeCompare(b.firstname);
                });

            } else {
                this.students.sort((a, b) => {
                    // Change 'propertyName' to the actual property you want to sort by
                    return b.firstname.localeCompare(a.firstname);
                });
            }
            this.sorted = !this.sorted;
        },
    }
};
</script>
  