<template>
    <div>
        <!-- <a class="btn btn-link text-danger text-gradient px-3 mb-0" href="javascript:;">
            <i class="far fa-trash-alt me-2" aria-hidden="true"></i>Delete
        </a> -->

        <button type="button" class="btn btn-link text-primary text-gradient px-3 mb-0 fw-normal" data-bs-toggle="modal"
            :data-bs-target="'#deleteModal' + student.id">Manage
        </button>

        <!-- Modal -->
        <div class="modal fade" :id="'deleteModal' + student.id" tabindex="-1" aria-labelledby="deleteModalLabel"
            aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered modal-lg">
                <!-- FORM HERE -->
                <form @submit.prevent="" class="modal-content">
                    <div class="modal-header px-4">
                        <h1 class="modal-title fs-5 text-black" id="deleteModalLabel">Fee Management</h1>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body text-left">
                        <div class="row m-0 p-0">
                            <div class="col-6 text-black ">
                                <h5>Expected Collection:</h5>
                            </div>
                            <div class="col-6 text-black ">
                                <h5 class="text-left">Rs {{ expected_collection }}</h5>
                            </div>
                        </div>
                        <div class="row m-0 p-0">
                            <div class="col-6">
                                <h5>Collected Fee</h5>
                            </div>
                            <div class="col-6">
                                <h5>Rs {{ total_collected }}</h5>

                            </div>
                            <div class="col-6">
                                <h5>Amount to be collected</h5>
                            </div>
                            <div class="col-6">
                                <h5>Rs {{ expected_collection - total_collected }}</h5>

                            </div>
                            <div class="col-6">
                                <!-- <h6 class="fs-6">{{ student.firstname }}</h6> -->
                                <!-- <h6 class="fs-6 fw-light">Remember! You can't undo this</h6> -->
                            </div>
                            <div class="col-6">
                                <input type="number" v-model="new_amount" placeholder="Amount"
                                    class="form-control form-sm rounded-0 p-1 px-2">
                            </div>
                            <div class="col-6">
                                <button @click="this.historyMode = !this.historyMode"
                                    class="btn btn-sm form-control btn-primary rounded-1 mt-2">
                                    <i class="bi bi-arrow-down"></i>
                                    View History
                                </button>
                            </div>
                            <div class="col-6 d-flex justify-content-end">
                                <button @click="startCollecting"
                                    class="btn btn-sm form-control btn-dark rounded-1 mt-2">Collect
                                    Fee</button>
                            </div>
                            <div class="col-12" v-if="historyMode">
                                <table class="table" style="font-size: 13px !important; color: black; font-weight: 500;">
                                    <thead>
                                        <tr>
                                            <td>
                                                SN
                                            </td>
                                            <td>Time</td>
                                            <td>Amount</td>
                                            <td @dblclick="this.editMode = !this.editMode">Edit On</td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(fee, index) in fees" :key="fee.id">
                                            <td>{{ index + 1 }}</td>
                                            <td>{{ parseDatetime(fee.created_at) }}</td>
                                            <td>Rs {{ fee.amount }}</td>
                                            <td @click="deleteThis(fee, index)" v-if="editMode"><i class="bi bi-trash"></i>
                                            </td>
                                            <td v-else><i class="bi bi-pen"></i></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                        </div>
                        <!-- <h6 class="fs-2 text-left">{{ student.firstname }}</h6> -->
                    </div>
                    <div class="modal-footer">
                        <button type="button" :id="'closeDeleteModal' + student.id" class="btn btn-dark"
                            data-bs-dismiss="modal">Close</button>
                        <!-- <button type="submit" @click="deletestudent()" class="btn btn-danger">{{ isloading ? 'Deleting' :
                            'Delete Now' }}
                        </button> -->
                    </div>
                </form>
                <!-- FORM END HERE -->
            </div>
        </div>
    </div>
</template>
<script>
import axiosConfig from "../../../baseaxios";
export default {
    props: ['student'],
    data() {
        return {
            isloading: false,
            expected_collection: 0,
            fees: [],
            total_collected: 0,
            new_amount: 0,
            historyMode: false,
            editMode: false,
        }
    },
    mounted() {
        console.log("I am active");
        for (let i = 0; i < this.student.courses.length; i++) {
            const course = this.student.courses[i];
            this.expected_collection = this.expected_collection + parseInt(course.fee_sp);
        }

        try {

            for (let i = 0; i < this.student.feestatus.fees.length; i++) {
                const fee = this.student.feestatus.fees[i];
                this.total_collected = this.total_collected + fee.amount;
            }
        } catch (error) {
            console.log(error);
        }

        try {
            this.fees = this.student.feestatus.fees;
        } catch (error) {
            console.log(error)
        }
    },
    // created() {
    //     console.log("I created")
    // },
    methods: {
        // delete
        startCollecting() {

            // $(`#deleteModal${this.student.id}`).modal('toggle')
            axiosConfig.post(`/back/fees/save/`, {
                amount: this.new_amount,
                student: this.student.id,
            }).then(resp => {
                // document.getElementById(`closeDeleteModal${this.student.id}`).click();
                this.fees.push(resp.data.fee);
                console.log(resp.data);
                this.total_collected = this.total_collected + resp.data.fee.amount;
                this.new_amount = 0;
                // this.grabstudents();
            })
        },
        deleteThis(fee, index) {
            axiosConfig.post(`/back/fees/delete/${fee.id}`).then(resp => {
                console.log(resp);
                this.fees.pop(fee);
                console.log(index);
            })
            // alert(fee.id, index);
        }
        ,
        parseDatetime(datetime) {
            const parsedDate = new Date(datetime);

            // Format the date as you need
            const options = {
                year: 'numeric',
                month: 'long',
                day: 'numeric',
                // hour: 'numeric',
                // minute: 'numeric',
                // second: 'numeric',
                hour12: false
            };

            return parsedDate.toLocaleString('en-US', options);
        }
        // startCalculatingTotal() {
        //     for (let i = 0; i < this.fees.length; i++) {
        //         const fee = this.fees[i];
        //         this.total_collected = this.total_collected + fee.amount;
        //     }
        // }
    }
}
</script>

<style>
.text-left {
    text-align: left;
}
</style>